<template>
  <div>
    <p>授权成功</p>
  </div>
</template>

<script>
export default {
  name: "wechatSuccess"
}
</script>

<style scoped>

</style>